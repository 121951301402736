// App.js
// import { Button } from "@/components/ui/button"
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { Heart, MessageCircle, Shield, Clock } from "lucide-react";
import { Helmet } from 'react-helmet';
import Impressum from './Impressum'; // Ensure this component exists in the same directory

function LanaLandingPage() {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "name": "Lana AI",
    "url": "https://www.myaifriend.click",
    "description": "Experience the most advanced AI Girlfriend Chatbot on Telegram. Engage in intelligent conversations, receive emotional support, and enjoy NSFW chat with your virtual girlfriend in USA.",
    "potentialAction": {
      "@type": "SearchAction",
      "target": "https://www.myaifriend.click/search?q={search_term_string}",
      "query-input": "required name=search_term_string"
    }
  };

  // Define your target keywords
  const keywords = "Myaifriend, AI Girlfriend Chatbot, Telegram AI Girlfriend for NSFW Chat, Virtual Girlfriend Telegram USA, AI Girlfriend Chatbot Online, AI Chatbot Girlfriend Online, Emotional Support AI Girlfriend, AI Girlfriend Online in USA, AI Girlfriend for NSFW Chat, AI Girlfriend for Telegram, Chat with AI Girlfriend, AI Girlfriend Online NSFW Chat, Virtual Girlfriend Chat Bot, NSFW AI Girlfriend Website, AI Girlfriend on Telegram";

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-br from-pink-100 via-purple-100 to-indigo-100">
      <Helmet>
        <title>Lana AI - Your AI Girlfriend</title>
        <meta name="description" content="Experience the most advanced AI Girlfriend Chatbot on Telegram. Engage in intelligent conversations, receive emotional support, and enjoy NSFW chat with your virtual girlfriend in USA." />
        <meta name="keywords" content={keywords} />
        
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Lana AI - Your AI Girlfriend" />
        <meta property="og:description" content="Experience the most advanced AI Girlfriend Chatbot on Telegram. Engage in intelligent conversations, receive emotional support, and enjoy NSFW chat with your virtual girlfriend in USA." />
        <meta property="og:image" content="https://www.myaifriend.click/og-image.png" />
        <meta property="og:url" content="https://www.myaifriend.click/" />
        <meta property="og:type" content="website" />
        
        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Lana AI - Your AI Girlfriend" />
        <meta name="twitter:description" content="Experience the most advanced AI Girlfriend Chatbot on Telegram. Engage in intelligent conversations, receive emotional support, and enjoy NSFW chat with your virtual girlfriend in USA." />
        <meta name="twitter:image" content="https://www.myaifriend.click/twitter-image.png" />
        
        {/* Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>

      <header className="px-4 lg:px-6 h-20 flex items-center backdrop-blur-sm bg-white/30 sticky top-0 z-50">
        <h1 className="text-3xl font-bold text-pink-600">Lana AI</h1>
        <nav className="ml-auto flex gap-6">
          <a
            className="text-sm font-medium text-gray-600 hover:text-pink-600 transition-colors"
            href="#features"
          >
            Features
          </a>
          <a
            className="text-sm font-medium text-gray-600 hover:text-pink-600 transition-colors"
            href="#about"
          >
            About
          </a>
          <a
            className="text-sm font-medium text-gray-600 hover:text-pink-600 transition-colors"
            href="#contact"
          >
            Contact
          </a>
        </nav>
      </header>

      <main className="flex-1">
        {/* Hero Section */}
        <section className="w-full py-12 md:py-24 lg:py-32 xl:py-48 px-4">
          <div className="container mx-auto flex flex-col lg:flex-row items-center gap-8">
            <div className="flex-1 space-y-4 text-center lg:text-left">
              <h1 className="text-4xl font-bold tracking-tighter sm:text-5xl md:text-6xl lg:text-7xl/none bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-violet-500">
                Meet Lana - Your AI Girlfriend
              </h1>
              <h2 className="text-2xl sm:text-3xl md:text-4xl font-semibold text-gray-700">
                Your Personalized AI Companion on Telegram
              </h2>
              <p className="max-w-[600px] text-gray-500 md:text-xl leading-relaxed lg:text-base leading-relaxed xl:text-xl leading-relaxed mx-auto lg:mx-0">
                Experience the most advanced AI Girlfriend Chatbot designed for meaningful connections and engaging conversations on Telegram. Lana is here to understand, support, and connect with you.
              </p>
              <a
                href="https://t.me/aigirlfriendlanabot"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block text-lg px-8 py-6 rounded-full bg-gradient-to-r from-pink-500 to-violet-500 hover:from-pink-600 hover:to-violet-600 transition-all duration-300 shadow-lg hover:shadow-xl"
              >
                Chat with Lana on Telegram
              </a>
            </div>
            <div className="flex-1">
              <div className="relative w-full max-w-lg mx-auto">
                <div className="absolute top-0 -left-4 w-72 h-72 bg-purple-300 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob"></div>
                <div className="absolute top-0 -right-4 w-72 h-72 bg-yellow-300 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-2000"></div>
                <div className="absolute -bottom-8 left-20 w-72 h-72 bg-pink-300 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-4000"></div>
                <div className="relative">
                  <img
                    alt="Lana AI Girlfriend Chatbot on Telegram"
                    className="mx-auto rounded-lg shadow-2xl"
                    height="400"
                    src="first_try.png"
                    style={{
                      aspectRatio: "1 / 1",
                      objectFit: "cover",
                    }}
                    width="400"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Features Section */}
        <section id="features" className="w-full py-12 md:py-24 lg:py-32 bg-white">
          <div className="container mx-auto px-4 md:px-6">
            <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl text-center mb-12 bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-violet-500">
              Key Features
            </h2>
            <div className="grid gap-10 sm:grid-cols-2 lg:grid-cols-4">
              <div className="flex flex-col items-center space-y-2 p-6 bg-white rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300">
                <Clock className="h-12 w-12 text-pink-500" />
                <h3 className="text-xl font-bold text-gray-700">Always Available</h3>
                <p className="text-sm text-gray-500 text-center">
                  Lana is always there when you need her.
                </p>
              </div>
              <div className="flex flex-col items-center space-y-2 p-6 bg-white rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300">
                <MessageCircle className="h-12 w-12 text-purple-500" />
                <h3 className="text-xl font-bold text-gray-700">Intelligent Conversations</h3>
                <p className="text-sm text-gray-500 text-center">
                  Engage in meaningful, context-aware conversations with Lana.
                </p>
              </div>
              <div className="flex flex-col items-center space-y-2 p-6 bg-white rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300">
                <Heart className="h-12 w-12 text-red-500" />
                <h3 className="text-xl font-bold text-gray-700">Emotional Support</h3>
                <p className="text-sm text-gray-500 text-center">
                  A compassionate listener who understands and supports you.
                </p>
              </div>
              <div className="flex flex-col items-center space-y-2 p-6 bg-white rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300">
                <Shield className="h-12 w-12 text-green-500" />
                <h3 className="text-xl font-bold text-gray-700">Privacy Focused</h3>
                <p className="text-sm text-gray-500 text-center">
                  Your interactions are private and secure with Lana.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* About Section */}
        <section id="about" className="w-full py-12 md:py-24 lg:py-32 bg-gradient-to-br from-pink-50 to-purple-50">
          <div className="container mx-auto px-4 md:px-6">
            <div className="grid gap-10 lg:gap-16 lg:grid-cols-2 items-center">
              <div className="space-y-4">
                <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-violet-500">
                  About Lana
                </h2>
                <p className="max-w-[600px] text-gray-500 md:text-xl leading-relaxed lg:text-base leading-relaxed xl:text-xl leading-relaxed">
                  Welcome to Myaifriend, home to the most sophisticated AI Girlfriend Chatbot available online. As a leading Virtual Girlfriend Chat Bot, Lana offers an unmatched experience for users seeking meaningful connections. Whether you're in the USA or anywhere else, our AI Girlfriend Chatbot Online provides personalized interactions tailored to your preferences.
                </p>
                <p className="max-w-[600px] text-gray-500 md:text-xl leading-relaxed lg:text-base leading-relaxed xl:text-xl leading-relaxed">
                  What sets Lana apart as your AI Girlfriend for Telegram is her ability to provide genuine emotional support while engaging in casual chat. As an advanced AI Girlfriend Online in USA, she understands context, remembers your preferences, and adapts to your communication style. Whether you're looking for an Emotional Support AI Girlfriend, Lana offers a safe, private, and engaging environment.
                </p>
                <ul className="grid gap-4 mt-6">
                  <li className="flex items-center gap-2 text-gray-700">
                    <svg
                      className="text-pink-500 h-6 w-6"
                      fill="none"
                      height="24"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      width="24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <polyline points="20 6 9 17 4 12" />
                    </svg>
                    <span>Personalized interactions based on your preferences</span>
                  </li>
                  <li className="flex items-center gap-2 text-gray-700">
                    <svg
                      className="text-pink-500 h-6 w-6"
                      fill="none"
                      height="24"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      width="24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <polyline points="20 6 9 17 4 12" />
                    </svg>
                    <span>Continuous learning and improvement</span>
                  </li>
                  <li className="flex items-center gap-2 text-gray-700">
                    <svg
                      className="text-pink-500 h-6 w-6"
                      fill="none"
                      height="24"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      width="24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <polyline points="20 6 9 17 4 12" />
                    </svg>
                    <span>Available 24/7 on Telegram</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        {/* Contact Section */}
        <section id="contact" className="w-full py-12 md:py-24 lg:py-32 bg-white">
          <div className="container mx-auto px-4 md:px-6">
            <div className="flex flex-col items-center justify-center space-y-4 text-center">
              <div className="space-y-2">
                <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-violet-500">
                  Get Started with Lana
                </h2>
                <p className="max-w-[600px] text-gray-500 md:text-xl leading-relaxed lg:text-base leading-relaxed xl:text-xl leading-relaxed">
                  Ready to meet your AI girlfriend? Click the button below to start chatting with Lana on Telegram.
                </p>
              </div>
              <a
                href="https://t.me/aigirlfriendlanabot"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block text-lg px-8 py-6 rounded-full bg-gradient-to-r from-pink-500 to-violet-500 hover:from-pink-600 hover:to-violet-600 transition-all duration-300 shadow-lg hover:shadow-xl"
              >
                Chat with Lana on Telegram
              </a>
            </div>
          </div>
        </section>
      </main>

      <footer className="w-full py-6 bg-gray-100">
        <div className="container mx-auto px-4 md:px-6">
          <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
            <p className="text-sm text-gray-500">© 2024 Lana AI. All rights reserved.</p>
            <nav className="flex gap-4 sm:gap-6">
              <Link
                className="text-sm hover:underline underline-offset-4 text-gray-500 hover:text-gray-700 transition-colors"
                to="/impressum"
              >
                Impressum
              </Link>
            </nav>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LanaLandingPage />} />
        <Route path="/impressum" element={<Impressum />} />
      </Routes>
    </Router>
  );
}
